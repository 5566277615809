<template>
  <div class="user-phone-pe-success text-center">
    <div
      v-if="successRequest && !processing"
      class="payment-form-done-bg"
    >
      <!-- <div style="border-radius: 200px; height: 200px; width: 200px; background: #f8faf5; margin: 0 auto">
        <i class="checkmark">✓</i>
      </div>
      <h1 class="mt-2">
        Payment Successful
      </h1>
      <p>
        We have successfully recieved your payment
      </p>
      <p
        v-if="orderInfo.store_order_invoice_id"
        class="mt-2"
      >
        Order Number: <b>{{ orderInfo.store_order_invoice_id }}</b>
      </p> -->
      <order-placed-successfully
        :order-number="orderInfo.store_order_invoice_id"
        :sub-title="'We have successfully placed your order. Store will soon accept your order'"
      />
      <div class="col-md-12 text-center">
        <b-link
          class="btn btn-primary my-1"
          :to="{ path: '/user/store/order/' + $route.params.orderId }"
        >
          {{ $t('View Order') }}
        </b-link>
      </div>
    </div>
    <div
      v-else-if="!successRequest && !processing"
      class="payment-successfull card"
    >
      <div style="border-radius: 200px; height: 200px; width: 200px; background: #f8faf5; margin: 0 auto">
        <i class="checkmark">❌</i>
      </div>
      <h1 class="mt-2">
        {{ $t('Oops, something went wrong') }}
      </h1>
      <p>
        {{ $t('Please try again') }}
      </p>
    </div>
    <div
      v-else-if="!successRequest && processing"
      class="payment-successfull card"
    >
      <h1 class="mt-2">
        {{ $t('Please wait') }}
      </h1>
      <p>
        {{ $t('We are processing') }}
      </p>
    </div>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import OrderPlacedSuccessfully from './shared/OrderPlacedSuccessfully.vue'
import { useUserUi } from './useUser'

export default {
  components: {
    BLink,
    OrderPlacedSuccessfully,
  },
  data() {
    return {
      successRequest: false,
      processing: true,
      orderInfo: {},
    }
  },
  mounted() {
    this.phonePeSuccess()
  },
  methods: {
    goToRetry() {
      let redirectUrl = `/user/checkout-shop/checkout?orderId=${this.$route.params.orderId}&retry=1`
      if (this.$route.meta.requestType === 'SERVICE') {
        redirectUrl = `/user/service/checkout?orderId=${this.$route.params.orderId}&retry=1`
      }
      this.$router.push({
        path: redirectUrl,
      })
    },
    phonePeSuccess() {
      let postUrl = `${this.$route.params.orderId}/${this.$route.meta.requestType}`
      if(this.$route.params.transactionId){
        postUrl += `/${this.$route.params.transactionId}`
      }
      const { phonePeSuccess } = useUserUi()
      showLoader()
      phonePeSuccess(postUrl)
        .then(({ data }) => {
          this.processing = false
          if (data.statusCode === '200') {
            this.successRequest = true
            this.orderInfo = data.responseData
            window.SuperApp.actions.newOrder()
            showSuccessNotification(this, data.message)
          } else {
            this.goToRetry()
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.processing = false
          this.goToRetry()
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-successfull {
  h1 {
    color: #88b04b;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
  }
  p {
    color: #404f5e;
    font-size: 20px;
    margin: 0;
  }
  i {
    color: #9abc66;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
  }
  &.card {
    background: white;
    padding: 60px;
    border-radius: 4px;
    border: 0;
    box-shadow: 0 2px 3px #c8d0d8;
    display: inline-block;
    margin: 0 auto;
  }
}
</style>
